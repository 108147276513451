// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "./controllers"
import "bootstrap"
import './add_jquery'


$(document).ready(function() {
  $('#phone-input').intlTelInput({
    initialCountry: 'auto',
    preferredCountries: ['us', 'gb', 'es'], // Add preferred country codes
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js', // Include the utils.js script
  });
});
